<template>
  <Login>
    <template v-slot:title>
      <h2 class="white--text text-center mb-5">Welcome to your Sony Rebate & SPIFF Center</h2>
    </template>
  </Login>
</template>

<script>
import Login from "../common/Login";

export default {
  name: "SonyChannelPartnerLogin",
  metaInfo: {
    title: "Sony Channel Partner Login"
  },
  components: {
    Login
  }
};
</script>
